import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        userInfoData: {},
        userProgressData: {},
        userAvatarData: null,
        userTapData: {},
        intervalAvailableTapsId: null,
        cardsList: [],
        openedModal: null,
        popupNotifi: null,
        valuePassiveIncome: 0,
        intervalPassiveIncome: null
    }),

    getters: {
        getUserInfoData(state) {
            return state.userInfoData;
        },
        getUserProgressData(state) {
            return state.userProgressData;
        },
        getUserAvatarData(state) {
            return state.userAvatarData;
        },
        getUserTapData(state) {
            return state.userTapData;
        },
        getCards(state) {
            return state.cardsList;
        },
        getOpenedModal(state) {
            return state.openedModal;
        },
        getPopupNotifi(state) {
            return state.popupNotifi;
        },
        getValuePassiveIncome(state) {
            return state.valuePassiveIncome;
        }
    },

    actions: {
        minusCoins(value) {
            if (value < 0) return
            this.userProgressData.coin_balance -= value;
        },

        plusCoins(value) {
            if (value < 0) return
            this.userProgressData.coin_balance += value;
        },

        saveUserData(value) {
            const { user, progress, avatar, tap } = value;

            this.userInfoData = user;
            this.userProgressData = progress;
            this.userAvatarData = avatar;
            this.userTapData = tap;
        },

        decrementAvailableTaps(decrementAvailableTaps) {
            let amtDecrementTabs = this.userProgressData.tap_profit*decrementAvailableTaps;
            let availableTaps = this.userTapData.availableTaps;

            if (amtDecrementTabs > availableTaps) {
                this.userTapData.availableTaps = 0;
                amtDecrementTabs = availableTaps;
            }else{
                this.userTapData.availableTaps -= amtDecrementTabs;
            }

            this.userProgressData.coin_balance += amtDecrementTabs;
        },

        startIntervalAvailableTaps() {
            if (this.intervalAvailableTapsId) return

            this.intervalAvailableTapsId = setInterval(() => {
                let currentPower = this.userTapData.availableTaps;
                let maxPower = this.userProgressData.dragon_power;

                if (currentPower < maxPower) {
                    let addTap = 3;
                    if ( (maxPower-currentPower) < addTap ) addTap = (maxPower-currentPower);

                    this.userTapData.availableTaps += addTap;
                }
            }, 1500);
        },

        saveCardList(value) {
            this.cardsList = value;
        },

        addNewLevel() {
            this.userProgressData.level += 1;
            this.userProgressData.dragon_power += 500;
        },

        openModal(name) {
            if (name === null) {
                document.body.style.overflow = "visible";
            }else{
                document.body.style.overflow = "hidden";
            }

            this.openedModal = name;
        },

        addPopupNotifi(value) {
            this.popupNotifi = value;

            if (value != null) {
                setTimeout(() => {
                    this.popupNotifi = null;
                }, 2200);
            }
        },

        saveValuePassiveIncome(value) {
            if (value < 0) value = 0;
            this.valuePassiveIncome = value;
        },

        startIntervalPassiveIncome() {
            if (this.intervalPassiveIncome) return

            this.intervalPassiveIncome = setInterval(() => {
                let coinsPerSecond = this.valuePassiveIncome / 3600;
                this.plusCoins(coinsPerSecond);
            }, 1000);
        }
    }
})