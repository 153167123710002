<template>
	<template v-if="data_loaded">
		<router-view />
		<Navbar />

		<div class="notification" v-if="$userStore.getPopupNotifi">
			<div class="notification__item">
				<img v-if="$userStore.getPopupNotifi.type == 0" class="notification__img"
					src="@/assets/img/notification-warning.svg" alt="Icon" />
				<img v-if="$userStore.getPopupNotifi.type == 1" class="notification__img"
					src="@/assets/img/notification-success.svg" alt="Icon" />
				<div class="notification__info">
					<span>{{ $userStore.getPopupNotifi.text }}</span>
				</div>
				<button class="notification__close-btn" @click="$userStore.addPopupNotifi(null)">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.6">
							<path d="M6 6L18 18" stroke="white" stroke-width="1.5" stroke-linecap="round" />
							<path d="M18 6L6.00001 18" stroke="white" stroke-width="1.5" stroke-linecap="round" />
						</g>
					</svg>
				</button>
			</div>
		</div>

		<div class="popup" :class="{ 'is-open': $userStore.getOpenedModal === 'claimedPassiveIncome' }">
			<div class="popup__inner">

				<div class="popup-image-card-wrapper">
					<img class="popup__img" src="@/assets/img/card-img1.webp" />
				</div>

				<div class="popup__info">
					<div class="cta__price">
						<span class="cta__price-number">
							{{ $abbreviateNumber(passiveIncome) }}
						</span>
					</div>
					<p>Your passive income</p>
				</div>

				<div class="popup__buttons">
					<div class="popup__btn popup__btn-blue" :class="{ 'opaciti05': waitClaimPassiveIncome }"
						@click="claimedPassiveIncome()">
						Claim
						<img width="16" height="16" src="@/assets/img/dragon-coin-sm.svg" alt="Dragon coin">
						<span>{{ $abbreviateNumber(passiveIncome) }}</span>
					</div>
				</div>
			</div>
		</div>
	</template>

	<template v-else>
		<loading :lock-scroll="true" :active="true" :is-full-page="true" color="#566eb3" loader="spinner"
			class="preloaderData" :height="72" :width="72" />
	</template>
</template>

<script>
import Navbar from '@/components/NavbarComponent.vue';

import Loading from 'vue3-loading-overlay';
import levelUpCoins from '@/fnc/levelUpCoins.js';

export default {
	components: {
		Navbar,
		Loading,
	},

	data: () => ({
		data_loaded: false,
		passiveIncome: 0,
		waitClaimPassiveIncome: false
	}),

	methods: {
		async claimedPassiveIncome() {
			if (this.waitClaimPassiveIncome === true) return
			this.waitClaimPassiveIncome = true;

			let api = await this.$postman('user/id.php', this.TWA.initData, { action: 'claim_passive_income' });
			if (api && api?.claimed_passive_income && api.claimed_passive_income > 0) {
				this.$userStore.plusCoins(api.claimed_passive_income);
			}

			this.$userStore.openModal(null);
			this.waitClaimPassiveIncome = false;
		},

		async getUserData() {
			let passive_income = 0;

			let api = await this.$postman('user/id.php', this.TWA.initData);
			
			if (api?.error) {
				if (api.error == 'invalidUser!' || api.error == 'unknownUserId!') {
					this.TWA.showAlert(`Произошла ошибка! Перезапустите приложение.`);
					return
				}
			}

			if (api && api?._success_loaded && api._success_loaded === true) {
				if (api?.user && api.user?.lang) {
					this.$i18n.locale = api.user.lang;
				}
				
				this.$userStore.saveUserData(api);
				this.$userStore.startIntervalAvailableTaps();

				if (api?.passiveIncome && api.passiveIncome > 0) {
					passive_income = api.passiveIncome;
				}
			}

			let cards = await this.$postman('user/cards.php', this.TWA.initData, { id: 0, type: 'get' });
			this.$userStore.saveCardList(cards.list);
			this.$userStore.saveValuePassiveIncome(cards.valuePassiveIncome);
			this.$userStore.startIntervalPassiveIncome();

			if (!this.data_loaded) {
				this.data_loaded = true;

				this.TWA.ready();
				this.TWA.disableVerticalSwipes();

				if (passive_income > 0) {
					this.passiveIncome = passive_income;
					setTimeout(() => {
						this.$userStore.openModal('claimedPassiveIncome');
					}, 100);
				}
			}
		},

		handleOutsideClick(event) {
			if (event.target.closest('.main') && this.$userStore.getOpenedModal === null) {
				this.$userStore.openModal(null);
			}
		},

		backButtonClicked() {
			this.$router.push({ path: '/' });
		}
	},

	mounted() {
		this.$userStore.openModal(null);
		// document.addEventListener('click', this.handleOutsideClick);

		this.TWA.onEvent('backButtonClicked', this.backButtonClicked);

		if (!this.TWA.isExpanded) {
			this.TWA.expand();
		}

		let checkExpandInterval = setInterval(() => {
			if (!this.TWA.isExpanded) {
				this.TWA.expand();
			} else {
				clearInterval(checkExpandInterval);

				const htmlElement = document.querySelector('html');

				// Извлекаем значение CSS-переменной --tg-color-scheme
				const styleString = htmlElement.getAttribute('style');
				const parsedStyles = styleString.split(';').reduce((acc, param) => {
					const [key, value] = param.split(':').map(item => item.trim());
					if (key) acc[key] = value;
					return acc;
				}, {});

				const colorScheme = parsedStyles['--tg-color-scheme'];

				if (colorScheme === 'light') {
					document.body.classList.add('light');
					document.body.classList.remove('dark');
				} else if (colorScheme === 'dark') {
					document.body.classList.add('dark');
					document.body.classList.remove('light');
				}
				// this.$userStore.setTheme(colorScheme);
			}
		}, 200);

		this.getUserData();
	},

	beforeUnmount() {
		// document.removeEventListener('click', this.handleOutsideClick);
		this.TWA.offEvent('backButtonClicked', this.backButtonClicked);
	},

	watch: {
		'$userStore.getUserProgressData.coin_balance'(newValue) {
			if (this.$userStore.getUserProgressData.level > 0) {
				if (
					levelUpCoins(this.$userStore.getUserProgressData.level) > 0
					&& newValue >= levelUpCoins(this.$userStore.getUserProgressData.level)
				) {
					this.$userStore.addNewLevel();
				}
			}
		},

		'$route'(newValue) {
			if (newValue.name == 'home') {
				this.TWA.BackButton.hide();
			}else{
				this.TWA.BackButton.show();
			}
		}
	},
}
</script>

<style>
.preloaderData {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 44vh;
}
</style>