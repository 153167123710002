export default {
    navigation: {
        dev: 'Карты',
        referrals: 'Друзья',
        home: 'База',
        bonus: 'Бонус',
        airDrop: 'AirDrop'
    },

    home: {
        tapProfit: 'Тап-профит',
        levelUp: 'След. lvl',
        profitOnHour: 'Прибыль в час',
        level: 'Уровень',
        dragonPower: 'Мощность',
        boosts: 'Бусты'
    }
};
